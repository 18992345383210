<template>
    <v-container fluid class="full-height">
        <v-row class="full-height" no-gutters>
            <v-col cols="12" sm="6" class="column-flex">
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeh34wzmamtGJprMFFX8Kpez55au_YPOrE_wppM6DOXRKUExA/viewform?usp=share_link" frameborder="0" class="iframe-flex"></iframe>
            </v-col>
            <v-col cols="12" sm="6" class="column-flex">
                <v-card-text>
                    <p>In today's digital era, maintaining a solid online footprint is no longer a mere luxury but a crucial necessity for all organizations, including non-profits and charitable institutions. As internet usage continues to expand, those charities that effectively utilize digital channels are more likely to connect with their donors, recipients, volunteers, and advocates.</p>
                    <p>This is why Mathison Projects, Inc. envisioned Reimagining Charity as an initiative to accelerate, and encourage three social organizations dedicated to improving quality of life, by donating money and services. These donations go toward supporting digitalization, and offer integral mentoring and support.</p>
                    <p>Any organization in the United States and Argentina can apply by filling out the form on the left-hand side. [Note: all questions are mandatory.] The final contest will be evaluated and pitched in an event in Nevada, USA, and Buenos Aires, Argentina.</p>
                    <p><strong>Charities and organizations can be eligible for up to USD $15,000 in donations and services.</strong></p>
                </v-card-text>
                <v-btn color="primary" :to="'/about'">More About Mathison Projects, Inc.</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'ReimaginingCharity',
    data() {
        return {
            // data properties go here
        }
    },
    methods: {
        // methods go here
    },
    computed: {
        // computed properties go here
    },
    created() {
        // lifecycle hook
    }
}
</script>
<style scoped>
.full-height {
    height: 100vh;
    align-items: stretch;
    min-height: 100vh;
}
.column-flex {
    display: flex;
    flex-direction: column;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-bottom: 1rem !important;
}
.iframe-flex {
    flex-grow: 1;
}
.v-row {
    display: flex;
}
.v-col {
    max-width: 50%;
    min-width: 50%;
    padding: 1rem;
}
</style>